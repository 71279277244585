<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import useGetAssignmentInstanceStudentInsight from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import useDismissTaskAttempt from '@/composables/api/mutations/useDismissTaskAttempt'
import { MultiTable } from '@/components/modern/ui/data-table'
import { useColumns, useGroups } from '@/components/modern/tables/instance-attempts'
import { Button } from '@/components/modern/ui/button'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { DownloadIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Individual Student Attempts',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Student Attempts')

const route = useRoute('Modern Cohorts - Individual Student Attempts')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)
const userId = computed(() => route.params.userId)

const notificationStore = useNotificationStore()

const { isLoading, tasks, refetch } = useGetAssignmentInstanceStudentInsight({
  assignmentInstanceId,
  userId,
  notificationStore
})

const taskAttemptId = ref<string>('')
const { execute, reset } = useDismissTaskAttempt({ taskAttemptId, notificationStore })
const requestDismissTaskAttempt = (attemptId: string) => (taskAttemptId.value = attemptId)
const confirmDismissTaskAttempt = async () => execute().finally(reset).finally(refetch)

const data = computed(() => tasks.value.flatMap((task) => task.attempts))
const columns = useColumns({
  cohortId,
  assignmentInstanceId,
  userId,
  requestDismissTaskAttempt,
  confirmDismissTaskAttempt
})
const groups = useGroups(tasks)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{
          name: 'Modern Cohorts - Activity Instance',
          params: { cohortId, assignmentInstanceId }
        }"
        name="activity"
      />
    </template>
    <template v-if="false" #right>
      <Button variant="default" size="xs" disabled>
        <DownloadIcon class="mr-2 size-4" />
        <span>Export</span>
      </Button>
    </template>
  </TopLine>
  <AppLoadingSpinner v-if="isLoading" :loading="isLoading" center-screen />
  <MultiTable v-else :data="data" :columns="columns" :groups="groups" :loading="isLoading" />
</template>
