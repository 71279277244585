<script lang="ts" setup>
import { ref, watch } from 'vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/modern/ui/select'
import { cn } from '@/lib/utils'
import { useAuthStore } from '@/stores/auth'
import { Role } from '@/types/auth'
import { type RoleString } from '@/types/auth'

const authStore = useAuthStore()

// fallback to superadmin because only true superadmins can see this
const syntheticRoleString = ref<RoleString | undefined>(
  authStore.role === Role.Student
    ? 'Student'
    : authStore.role === Role.Educator
      ? 'Educator'
      : authStore.role === Role.InstitutionAdmin
        ? 'InstitutionAdmin'
        : 'SuperAdmin'
)

const roleStrings: RoleString[] = ['Student', 'Educator', 'InstitutionAdmin', 'SuperAdmin']

watch(
  syntheticRoleString,
  () => {
    if (syntheticRoleString.value) {
      authStore.setSyntheticRole(syntheticRoleString.value)
    }
  }
  // { immediate: true }
)
</script>

<template>
  <Select v-model="syntheticRoleString">
    <SelectTrigger
      aria-label="admin role select"
      :class="
        cn(
          'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0'
        )
      "
    >
      <SelectValue placeholder="Select a role" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem v-for="role of roleStrings" :key="role" :value="role">
        <div
          class="flex items-center gap-3 [&_svg]:size-4 [&_svg]:shrink-0 [&_svg]:text-foreground"
        >
          {{ role }}
        </div>
      </SelectItem>
    </SelectContent>
  </Select>
</template>
