import './assets/main.css'

import { createApp, nextTick } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import * as Sentry from '@sentry/vue'
import posthog from 'posthog-js'
import $posthog from './utils/posthog'
import { auth0 } from '@/utils/auth0'
import App from './App.vue'
import createdRouter from '@/router'
import { HILBERT_URL, IS_LOCAL, IS_PRODUCTION } from './constants'

const app = createApp(App)

if (!IS_PRODUCTION) {
  app.config.warnHandler = (msg, instance, trace) => {
    if (msg.includes('Failed to resolve component: a-')) {
      return
      // Asynchronously loaded code does not use our build-time compiler options
      // So we have to manually suppress this error instead of using
      // compilerOptions: isCustomElement
    } else if (
      msg.includes('Avoid app logic that relies on enumerating keys on a component instance')
    ) {
      return
      // Vue Dev Tools causes this error multiple times per pageload
      // It enumerates component instance keys in the process of inspecting the component tree
    } else {
      // a slightly nicer error message and stack trace to boot
      console.warn(`[Vue warn] ${msg}\n%O\n${trace}`, instance)
    }
  }
}

if (!IS_LOCAL) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tunnel: `${HILBERT_URL}/SentryProxy`,
    release: '0.1.0',
    environment: import.meta.env.VITE_ENV,
    integrations: [
      posthog.sentryIntegration({
        organization: 'simconverse',
        projectId: import.meta.env.VITE_SENTRY_DSN
      })
    ],
    // https://docs.sentry.io/platforms/javascript/configuration/integrations/inboundfilters/
    ignoreErrors: [
      // Do not report "not logged in" errors to Sentry
      'You are not authorized to perform this action: You are not logged in',
      // These are thrown when the user is logged out.
      'Request failed with status code 401',
      'Not logged in',
      // Happens when we deploy and someone has their browser open.
      'Load failed',
      'Importing a module script failed.',
      // Chrome on iOS injects a __gCrWeb script that causes this error
      '__gCrWeb.edgeTranslate.detectPageState',
      // Caused by Microsoft Outlook's Safe Links scanner automatically scanning
      // our login links.
      'Non-Error promise rejection captured'
    ]
  })

  // Posthog
  app.use((app) => {
    app.config.globalProperties.$posthog = $posthog
  })
}
createdRouter.afterEach((to, _from, failure) => {
  if (!failure) {
    nextTick(() => {
      app.config.globalProperties.$posthog?.capture('$pageview', { path: to.fullPath })
    })
  }
})

app.use(createdRouter)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(auth0)

app.mount('#app')
