import type { CompletePreLearningTaskOutput } from '../models/CompletePreLearningTaskOutput';
import type { EndMyConversationTaskInput } from '../models/EndMyConversationTaskInput';
import type { EndMyConversationTaskOutput } from '../models/EndMyConversationTaskOutput';
import type { GetMyAssignmentOutput } from '../models/GetMyAssignmentOutput';
import type { GetMyPastConversationTaskAttemptsOutput } from '../models/GetMyPastConversationTaskAttemptsOutput';
import type { ListMyAssignmentsInput } from '../models/ListMyAssignmentsInput';
import type { ListMyAssignmentsOutput } from '../models/ListMyAssignmentsOutput';
import type { ListMyCohortsOutput } from '../models/ListMyCohortsOutput';
import type { StartMyConversationTaskInput } from '../models/StartMyConversationTaskInput';
import type { StartMyConversationTaskOutput } from '../models/StartMyConversationTaskOutput';
import type { StartMyFeedbackTaskInput } from '../models/StartMyFeedbackTaskInput';
import type { StartMyFeedbackTaskOutput } from '../models/StartMyFeedbackTaskOutput';
import type { StartPreLearningTaskOutput } from '../models/StartPreLearningTaskOutput';
import type { SubmitMyReflectionTaskInput } from '../models/SubmitMyReflectionTaskInput';
import type { SubmitMyReflectionTaskOutput } from '../models/SubmitMyReflectionTaskOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';


export class MyAssignmentsService {

	/**
	 * CompletePreLearningTask
	 * Completes a pre-learning task.
	 * @param prelearningTaskAttemptId The ID of the attempt to complete.
	 * @returns CompletePreLearningTaskOutput Completed pre-learning task.
	 * @throws ApiError
	 */
	public static completePrelearningTaskEndpoint(
prelearningTaskAttemptId: string,
): CancelablePromise<CompletePreLearningTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/CompletePreLearningTask',
			query: {
				prelearning_task_attempt_id: prelearningTaskAttemptId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * EndMyConversationTask
	 * End a student user's conversation task.
	 * @param requestBody 
	 * @returns EndMyConversationTaskOutput My conversation task ended successfully.
	 * @throws ApiError
	 */
	public static endMyConversationTaskEndpoint(
requestBody: EndMyConversationTaskInput,
): CancelablePromise<EndMyConversationTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/EndMyConversationTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to end my conversation task.`,
			},
		});
	}

	/**
	 * GetMyAssignment
	 * Retrieve student user's assignment by ID.
	 * @param assignmentId The ID of the assignment to retrieve.
	 * @returns GetMyAssignmentOutput My assignment retrieved successfully.
	 * @throws ApiError
	 */
	public static getMyAssignmentEndpoint(
assignmentId: string,
): CancelablePromise<GetMyAssignmentOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/my-assignments/GetMyAssignment',
			query: {
				assignment_id: assignmentId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this assignment.`,
			},
		});
	}

	/**
	 * GetMyCohorts
	 * Get student user's cohorts
	 * @returns ListMyCohortsOutput My cohorts retrieved successfully.
	 * @throws ApiError
	 */
	public static getMyCohortsEndpoint(): CancelablePromise<ListMyCohortsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/my-assignments/GetMyCohorts',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query my cohorts.`,
			},
		});
	}

	/**
	 * GetMyPastConversationTaskAttempts
	 * Get user's past conversation task attempts for the given task id.
	 * @param taskId The ID of the task to get past conversation task attempts.
	 * @returns GetMyPastConversationTaskAttemptsOutput Past conversation task attempts retrieved successfully.
	 * @throws ApiError
	 */
	public static getMyPastConversationTaskAttemptsEndpoint(
taskId: string,
): CancelablePromise<GetMyPastConversationTaskAttemptsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/my-assignments/GetMyPastConversationTaskAttempts',
			query: {
				task_id: taskId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query past conversation task attempts.`,
			},
		});
	}

	/**
	 * ListMyAssignments
	 * List student user's assignments for the given cohorts.
	 * @param requestBody 
	 * @returns ListMyAssignmentsOutput Assignments retrieved successfully.
	 * @throws ApiError
	 */
	public static listMyAssignmentsEndpoint(
requestBody: ListMyAssignmentsInput,
): CancelablePromise<ListMyAssignmentsOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/ListMyAssignments',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query assignments.`,
			},
		});
	}

	/**
	 * StartMyConversationTask
	 * Start a student user's conversation task.
	 * @param requestBody 
	 * @returns StartMyConversationTaskOutput My conversation task started successfully.
	 * @throws ApiError
	 */
	public static startMyConversationTaskEndpoint(
requestBody: StartMyConversationTaskInput,
): CancelablePromise<StartMyConversationTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/StartMyConversationTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to start my conversation task.`,
			},
		});
	}

	/**
	 * StartMyFeedbackTask
	 * Start a student user's feedback task.
	 * @param requestBody 
	 * @returns StartMyFeedbackTaskOutput My feedback task started successfully.
	 * @throws ApiError
	 */
	public static startMyFeedbackTaskEndpoint(
requestBody: StartMyFeedbackTaskInput,
): CancelablePromise<StartMyFeedbackTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/StartMyFeedbackTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to start my feedback task.`,
			},
		});
	}

	/**
	 * StartPreLearningTask
	 * Starts a pre-learning task.
	 * @param taskId The ID of the task to start.
	 * @returns StartPreLearningTaskOutput Started pre-learning task.
	 * @throws ApiError
	 */
	public static startPrelearningTaskEndpoint(
taskId: string,
): CancelablePromise<StartPreLearningTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/StartPreLearningTask',
			query: {
				task_id: taskId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SubmitMyReflectionTask
	 * Submits a reflection task.
	 * @param requestBody 
	 * @returns SubmitMyReflectionTaskOutput Submitted reflection task.
	 * @throws ApiError
	 */
	public static submitMyReflectionTaskEndpoint(
requestBody: SubmitMyReflectionTaskInput,
): CancelablePromise<SubmitMyReflectionTaskOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/my-assignments/SubmitMyReflectionTask',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

}