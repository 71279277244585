<script setup lang="ts">
import { usePageHeadingStore } from '@/stores/pageHeading'
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useGetOrganizationCohorts from '@/composables/api/queries/useGetOrganizationCohorts'
import { TopLine } from '@/components/modern/page-navigation'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/organization-cohorts'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Administration',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    isModern: true
  }
})
const authStore = useAuthStore()

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('All Cohorts')

const notificationStore = useNotificationStore()

const organizationId = computed(() => authStore.organizationId!)
const { isLoading, cohorts } = useGetOrganizationCohorts({ organizationId, notificationStore })

const organizationCohorts = useColumns()
</script>

<template>
  <TopLine>
    <template #left>
      <span>All Cohorts</span>
    </template>
    <template #right>
      <Button variant="default" size="xs" as-child>
        <RouterLink :to="{ name: 'Modern Cohorts - New Cohort' }">
          <PlusIcon class="mr-2 size-4" />
          <p>Create Cohort</p>
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable :data="cohorts" :columns="organizationCohorts" :loading="isLoading" />
</template>
