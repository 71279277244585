<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { isPrelearning, isFeedback } from '@/utils/task-types'
import useGetMyAssignmentInstance from '@/composables/api/queries/useGetMyAssignmentInstance'
import useGetTaskAttempt from '@/composables/api/queries/useGetTaskAttempt'
import useCombineQueries from '@/composables/api/useCombineQueries'
import useTaskAttemptState from '@/composables/useTaskAttemptState'
import useExitTaskAttempt from '@/composables/api/useExitTaskAttempt'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { TopLine, BackButton, ForwardButton } from '@/components/modern/page-navigation'
import { PerformTask, ReviewTask, type ExitAttemptHandler } from '@/components/modern/task-attempts'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'

definePage({
  name: 'Modern - Attempt Task or Review Task Attempt',
  meta: {
    permissionLevel: 'Student',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Task Attempt')

// Extract information from the route
const route = useRoute('Modern - Attempt Task or Review Task Attempt')
const assignmentInstanceId = computed(() => route.params.activityId)
const taskId = computed(() => route.params.taskId)
const taskAttemptId = computed(() => route.params.taskAttemptId)

// Fetch data from the server
const notificationStore = useNotificationStore()
const { track, isLoading, refetch } = useCombineQueries()
const { assignmentInstance } = track(
  useGetMyAssignmentInstance({
    assignmentInstanceId,
    notificationStore
  })
)
const { taskAttempt } = track(useGetTaskAttempt({ taskAttemptId, notificationStore }))

// Compose data to derive state of task attempt
const task = computed(
  () => assignmentInstance.value?.tasks.find((t) => t.task_id === taskId.value) ?? null
)
const {
  completed,
  canLeaveAttempt,
  inRubric,
  showRubric,
  canShowRubric,
  hideRubric,
  canHideRubric
} = useTaskAttemptState({ task, taskAttempt })

const exitTaskAttempt = useExitTaskAttempt({
  assignmentInstanceId,
  taskId,
  taskAttemptId,
  notificationStore
})

const router = useRouter()

const submitting = ref<boolean>(false)
const handleCompleted: ExitAttemptHandler = async (...args) => {
  submitting.value = true
  await exitTaskAttempt(...args)
  if (isPrelearning(task) || isFeedback(task)) {
    router.push({
      name: 'Modern - Learner Activity View',
      params: { activityId: assignmentInstanceId.value }
    })
  }
  refetch()
  submitting.value = false
}

useScrollToTopOnChange([completed, inRubric])
</script>

<template>
  <TopLine v-if="canHideRubric || !isLoading">
    <template #left>
      <BackButton v-if="canHideRubric" name="transcript" @click="hideRubric" />
      <BackButton
        v-else-if="canLeaveAttempt"
        :to="{
          name: 'Modern - Learner Activity View',
          params: { activityId: assignmentInstanceId }
        }"
        name="activity"
      />
    </template>
    <template v-if="!isLoading" #right>
      <ForwardButton v-if="canShowRubric" text="Continue to feedback" @click="showRubric" />
      <ForwardButton
        v-else-if="completed"
        :to="{
          name: 'Modern - Learner Activity View',
          params: { activityId: assignmentInstanceId }
        }"
        text="Return to activity"
      />
    </template>
  </TopLine>
  <AppLoadingSpinner v-if="isLoading" loading center-screen />
  <ReviewTask
    v-else-if="completed && task && taskAttempt"
    :task-attempt-id="taskAttemptId"
    :task-payload="task.payload"
    :attempt-payload="taskAttempt.payload"
    :in-rubric="inRubric"
  />
  <PerformTask
    v-else-if="task"
    :task-payload="task.payload"
    :attempt-payload="taskAttempt?.payload ?? null"
    :handle-completed="handleCompleted"
    :submitting="submitting"
  />
</template>
