import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { roleGuard } from '@/router/guard/roleGuard'
import { Role } from '@/types/auth'
import { checkSessionOutsideComponent, getIsAuthenticatedOutsideComponent } from '@/utils/auth0'
import { IS_LOCAL } from '@/constants'

export const globalBeforeEachGuard = async (
  to: RouteLocationNormalized
): Promise<boolean | RouteLocationRaw> => {
  if (!to.meta) {
    // route is not configured correctly
    if (IS_LOCAL) {
      console.warn('Route not correctly configured with meta properties: ', to)
    }
    return false
  }
  const routeRole = Role[to.meta.permissionLevel]
  const isPublic = routeRole == Role.ANY

  if (isPublic) {
    return true
  }

  if (!getIsAuthenticatedOutsideComponent().value) {
    // Nested logic needed to handle the case where the page is refreshed and auth0 needs to load again
    await checkSessionOutsideComponent()

    if (!getIsAuthenticatedOutsideComponent().value) {
      // User is not authenticated
      return '/login'
    }
  }

  return roleGuard(to)
}
