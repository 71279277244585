<script setup lang="ts">
import { ref, watch } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { onMounted } from 'vue'
import CustomTabs, { type TabOption } from '@/components/utils/CustomTabs.vue'
import { useRoute, useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'

definePage({
  name: 'Management',
  meta: {
    permissionLevel: 'Student'
  }
})

const authStore = useAuthStore()
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Settings')
const router = useRouter()
const route = useRoute<
  'Management' | 'Management Billing' | 'Management Users' | 'Management Organization'
>()

let tabOptions = ref<TabOption[]>([])

onMounted(() => {
  if (route.name === 'Management') {
    router.push({ name: 'Management Users' })
  }
  if (authStore.isAtLeastInstitutionAdminUser) {
    tabOptions.value = [
      ...tabOptions.value,
      {
        displayName: 'Users',
        routeName: 'Management Users'
      },
      {
        displayName: 'Billing',
        routeName: 'Management Billing'
      }
    ]
  }

  if (authStore.isSuperAdminUser) {
    tabOptions.value = [
      ...tabOptions.value,
      {
        displayName: 'Organization',
        routeName: 'Management Organization'
      },
      {
        displayName: 'Feature Flags',
        routeName: 'Management Feature Flags'
      }
    ]
  }
})

watch(route, () => {
  if (route.name === 'Management') {
    router.push({ name: 'Management Users' })
  }
})

watch(
  () => authStore.organizationId,
  (val) => {
    router.push({ name: 'Management Users', query: { orgId: val } })
  }
)
</script>

<template>
  <div
    :class="[
      'flex flex-col gap-y-5',
      { 'h-full overflow-hidden': route.name === 'Management Billing' }
    ]"
  >
    <CustomTabs v-if="tabOptions.length > 1" :tab-options="tabOptions" />
    <router-view class="px-5" />
  </div>
</template>
