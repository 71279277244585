<script setup lang="ts">
import { computed, type Ref } from 'vue'
import { ScrollArea } from '@/components/modern/ui/scroll-area'
import CollapsibleNav from './CollapsibleNav.vue'
import type { NavItemRoute } from './NavItem.vue'
import {
  Cog8ToothIcon,
  UsersIcon,
  LightBulbIcon,
  ClipboardDocumentListIcon,
  AcademicCapIcon,
  BriefcaseIcon,
  BookOpenIcon,
  PencilSquareIcon,
  BuildingOffice2Icon
} from '@heroicons/vue/24/outline'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyCohorts from '@/composables/api/queries/useGetMyCohorts'
import type { GetMyCohortsCohort } from '@/open-api/generated'
import InfoMenu from './InfoMenu.vue'
import NavItem from './NavItem.vue'
import OrganizationLabel from '@/components/modern/app-navigation-sidebar/OrganizationLabel.vue'

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

let cohorts: Ref<GetMyCohortsCohort[]>
if (authStore.isAtLeastEducatorUser) {
  cohorts = useGetMyCohorts({ notificationStore }).cohorts
}

const cohortRoutes = computed((): NavItemRoute[] => [
  ...(cohorts?.value
    ?.filter((c) => c.cohort_role === 'Educator')
    .map((cohort) => ({
      label: cohort.name,
      to: `/cohorts/${cohort.id}/activities`,
      icon: AcademicCapIcon
    })) || [])
])

const authoringRoutes = computed((): NavItemRoute[] => [
  { label: 'Characters', to: '/content/characters', icon: UsersIcon },
  { label: 'Rubrics', to: '/content/rubrics', icon: LightBulbIcon },
  { label: 'Sections', to: '/content/sections', icon: ClipboardDocumentListIcon },
  ...(authStore.flagModernEnabled || authStore.isSuperAdminUser
    ? [{ label: 'Activities', to: '/activities/', icon: PencilSquareIcon }]
    : [])
])

const assignRoutes: NavItemRoute[] = [
  { label: 'Activities', to: '/activities/', icon: PencilSquareIcon }
]

const administrationRoutes = computed((): NavItemRoute[] => [
  { label: 'Settings', to: '/management', icon: Cog8ToothIcon },
  ...(authStore.flagModernEnabled || authStore.isSuperAdminUser
    ? [{ label: 'Cohorts', to: '/cohorts/administer', icon: AcademicCapIcon }]
    : [])
])

const myActivitiesRoute: NavItemRoute = {
  label: 'My Activities',
  to: '/my-activities',
  icon: BriefcaseIcon
}

const myCohortsRoute: NavItemRoute = {
  label: 'My Cohorts',
  to: '/cohorts',
  icon: AcademicCapIcon,
  exact: true
}

const legacyRoutes = computed((): NavItemRoute[] => [
  ...(authStore.flagModernEnabled && !authStore.isSuperAdminUser
    ? []
    : [
        { label: 'My Assignments', to: '/my-assignments', icon: BriefcaseIcon },
        { label: 'My History', to: '/history', icon: BookOpenIcon }
      ]),
  ...(authStore.isAtLeastEducatorUser
    ? [
        { label: 'Cohorts', to: '/cohort', icon: AcademicCapIcon },
        { label: 'Organization History', to: '/organization/history', icon: BuildingOffice2Icon }
      ]
    : [])
])
</script>

<template>
  <aside class="flex h-full flex-col items-start justify-start gap-3 text-sm">
    <OrganizationLabel />
    <ScrollArea class="w-full flex-grow">
      <div class="flex h-full flex-col gap-2">
        <div class="flex flex-col gap-[1px]">
          <NavItem
            v-if="authStore.flagModernEnabled || authStore.isSuperAdminUser"
            :route="myActivitiesRoute"
          />
          <NavItem
            v-if="
              (authStore.flagModernEnabled && authStore.isAtLeastEducatorUser) ||
              authStore.isSuperAdminUser
            "
            :route="myCohortsRoute"
          />
        </div>
        <!-- We only want to show legacy routes to students if the org only has legacy and not modern -->
        <!-- We want to show to educators if legacy is still enabled -->
        <CollapsibleNav
          v-if="
            !authStore.flagModernEnabled ||
            (authStore.flagLegacyEnabled && authStore.isAtLeastEducatorUser) ||
            authStore.isSuperAdminUser
          "
          :deprecated="true"
          label="Legacy"
          :routes="legacyRoutes"
          :default-open="!authStore.flagModernEnabled"
        />
        <CollapsibleNav
          v-if="
            (authStore.flagAuthoringEnabled && authStore.isAtLeastEducatorUser) ||
            authStore.isSuperAdminUser
          "
          label="Author"
          :routes="authoringRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-else-if="
            authStore.flagModernEnabled &&
            !authStore.flagAuthoringEnabled &&
            authStore.isAtLeastEducatorUser
          "
          label="Assign"
          :routes="assignRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-if="authStore.isAtLeastInstitutionAdminUser"
          label="Administer"
          :routes="administrationRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-if="
            ((authStore.flagModernEnabled && authStore.isAtLeastEducatorUser) ||
              authStore.isSuperAdminUser) &&
            cohortRoutes.length > 0
          "
          label="Educate"
          :routes="cohortRoutes"
          :default-open="true"
        />
      </div>
    </ScrollArea>

    <InfoMenu class="size-8" />
  </aside>
</template>
