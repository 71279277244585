<script setup lang="ts">
import { watch, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import { UserRoleApiEnum, type GetCohortEducator } from '@/open-api/generated'
import useGetCohortV2 from '@/composables/api/queries/useGetCohortV2'
import useGetCohortEducators from '@/composables/api/queries/useGetCohortEducators'
import useSearchOrganizationUsers from '@/composables/api/queries/useSearchOrganizationUsers'
import useAddEducatorToCohort from '@/composables/api/mutations/useAddEducatorToCohort'
import useRemoveEducatorFromCohort from '@/composables/api/mutations/useRemoveEducatorFromCohort'
import useUpdateCohortV2 from '@/composables/api/mutations/useUpdateCohortV2'
import { schema, type Schema } from '@/composables/api/mutations/schema/useUpdateCohortV2'
import { CommandState } from '@/composables/api/mutations/types'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import {
  LABEL_COHORT_NAME,
  LABEL_COHORT_ISDEFAULT,
  DESCRIPTION_COHORT_NAME,
  DESCRIPTION_COHORT_ISDEFAULT
} from '@/constants'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/cohort-educators'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { Button } from '@/components/modern/ui/button'
import { Label } from '@/components/modern/ui/label'
import { Separator } from '@/components/modern/ui/separator'
import { CheckboxIcon, PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Details',
  meta: {
    permissionLevel: 'InstitutionAdmin',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohort Details')

const route = useRoute('Modern Cohorts - Cohort Details')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()
const organizationId = computed(() => authStore.organizationId!)

const notificationStore = useNotificationStore()

// Form setup

const { cohort, refetch: refetchCohort } = useGetCohortV2({ cohortId, notificationStore })
const updateCohort = useUpdateCohortV2({
  cohortId,
  notificationStore
})

const form = useForm({ validationSchema: toTypedSchema(schema) })

const fieldConfig: Config<Schema> = {
  name: {
    label: LABEL_COHORT_NAME,
    description: DESCRIPTION_COHORT_NAME
  },
  isDefault: {
    label: LABEL_COHORT_ISDEFAULT,
    description: DESCRIPTION_COHORT_ISDEFAULT,
    hideRequired: true
  }
}

// Fill in the form with the server values from GetCohort
watch(cohort, () => {
  form.resetForm({ values: mapCohortToFormValues(cohort) })
})

// Map the output of GetCohort to the input of UpdateCohort
const mapCohortToFormValues = (arg: typeof cohort): Schema | undefined => {
  if (!arg.value) {
    return undefined
  }
  return {
    name: arg.value.name,
    isDefault: arg.value.is_default
  }
}

const submit = form.handleSubmit(async (values) => {
  const { state, execute, reset } = updateCohort
  await execute(values)
  // On success, refetch GetCohort to ensure the form is filled with the values
  // the server was actually updated with.
  if (state.value === CommandState.SUCCESS) {
    refetchCohort()
  }
  // Reset the mutation so users can make further changes.
  reset()
})

// Table setup
const {
  cohortEducators: educators,
  refetch: refetchEducators,
  isLoading
} = useGetCohortEducators({ cohortId, notificationStore })

const removeUserId = ref<string | null>(null)
const { execute: removeEducator } = useRemoveEducatorFromCohort({
  cohortId,
  userId: removeUserId,
  notificationStore
})
const requestRemoveEducator = (educator: GetCohortEducator) => (removeUserId.value = educator.id)
const confirmRemoveEducator = async () => removeEducator().finally(refetchEducators)

const cohortEducators = useColumns({ requestRemoveEducator, confirmRemoveEducator })

// Action setup
const query = ref<string>('')
const { isLoading: usersLoading, users } = useSearchOrganizationUsers({
  organizationId,
  query,
  notificationStore
})

const userId = ref<string | undefined>(undefined)
const addEducator = useAddEducatorToCohort({ cohortId, userId, notificationStore })
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        :to="{ name: 'Modern Cohorts - Cohort Administration', params: { cohortId } }"
        name="cohort administration"
      />
    </template>
  </TopLine>
  <div class="mt-4 flex w-full flex-row justify-center lg:mt-6">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <p class="mb-4 text-xl font-medium">Details</p>
      <AutoForm :schema="schema" :form="form" :field-config="fieldConfig">
        <Button
          variant="default"
          size="xs"
          :disabled="updateCohort.state.value === CommandState.IN_PROGRESS"
          @click="submit"
        >
          <CheckboxIcon class="mr-2 size-4" />
          <span>Save Changes</span>
        </Button>
      </AutoForm>
    </div>
  </div>
  <div class="mt-2 flex w-full flex-row justify-center">
    <div class="mx-4 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <p class="mb-1 text-xl font-medium">Educators</p>
    </div>
  </div>
  <Separator />
  <DataTable
    :data="educators"
    :columns="cohortEducators"
    :loading="isLoading"
    empty="No educators in this cohort."
  />
  <Separator v-if="educators.length" />

  <div class="mt-2 flex w-full flex-row justify-center">
    <div class="mx-4 mb-8 max-w-4xl flex-1 grow space-y-2 lg:mx-6">
      <Label for="search-educators">Search for an educator to add to this cohort</Label>
      <SearchSelect
        id="search-educators"
        v-model:query="query"
        v-model:selected-value="userId"
        :data="
          users.filter((u) =>
            [
              UserRoleApiEnum.EDUCATOR,
              UserRoleApiEnum.INSTITUTION_ADMIN,
              UserRoleApiEnum.STAFF,
              UserRoleApiEnum.SUPER_ADMIN
            ].includes(u.role)
          )
        "
        value-key="id"
        label-key="email"
        placeholder-label="user"
        placeholder-label-plural="users"
        :loading="usersLoading"
      />
      <Button
        variant="default"
        size="xs"
        class="!mt-4"
        :disabled="addEducator.state.value === CommandState.IN_PROGRESS"
        @click="addEducator.execute().finally(addEducator.reset).finally(refetchCohort)"
      >
        <PlusIcon class="size-4" />
        <span>Add Educator</span>
      </Button>
    </div>
  </div>
</template>
