<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { TopLine, BackButton, ForwardButton } from '@/components/modern/page-navigation'
import useGetAssignmentInstanceStudentInsight from '@/composables/api/queries/useGetAssignmentInstanceStudentInsight'
import useGetTaskAttempt from '@/composables/api/queries/useGetTaskAttempt'
import useCombineQueries from '@/composables/api/useCombineQueries'
import useTaskAttemptState from '@/composables/useTaskAttemptState'
import useScrollToTopOnChange from '@/composables/useScrollToTopOnChange'
import { ReviewTask, ObserveConversationTask } from '@/components/modern/task-attempts'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'

definePage({
  name: 'Modern Educator - Review Task Attempt',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Task Attempt')

// Extract information from the route
const route = useRoute('Modern Educator - Review Task Attempt')
const cohortId = computed(() => route.params.cohortId)
const userId = computed(() => route.params.userId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)
const taskAttemptId = computed(() => route.params.taskAttemptId)

// Fetch data from server
const notificationStore = useNotificationStore()
const { track, isLoading } = useCombineQueries()
const { taskAttempt } = track(useGetTaskAttempt({ taskAttemptId, notificationStore }))
const { tasks } = track(
  useGetAssignmentInstanceStudentInsight({
    assignmentInstanceId,
    userId,
    notificationStore
  })
)

// Work backwards from attempt to find the task
const taskId = computed(() => taskAttempt.value?.task_id ?? null)
const task = computed(() => tasks.value?.find((t) => t.task_id === taskId.value) ?? null)

const { completed, inRubric, showRubric, canShowRubric, hideRubric, canHideRubric } =
  useTaskAttemptState({ task, taskAttempt })

// Scroll to top on appearance of page navigation
useScrollToTopOnChange(inRubric)
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton v-if="canHideRubric" name="transcript" @click="hideRubric" />
      <BackButton
        v-else
        :to="{
          name: 'Modern Cohorts - Individual Student Attempts',
          params: { cohortId, assignmentInstanceId, userId }
        }"
        name="student's attempts"
      />
    </template>
    <template v-if="!isLoading" #right>
      <ForwardButton v-if="canShowRubric" text="Continue to feedback" @click="showRubric" />
      <ForwardButton
        v-if="canHideRubric"
        :to="{
          name: 'Modern Cohorts - Individual Student Attempts',
          params: { cohortId, assignmentInstanceId, userId }
        }"
        text="Return to student's attempts"
      />
    </template>
  </TopLine>
  <AppLoadingSpinner v-if="isLoading" loading center-screen />
  <ReviewTask
    v-else-if="completed && task && taskAttempt"
    :task-attempt-id="taskAttemptId"
    :task-payload="task.payload"
    :attempt-payload="taskAttempt.payload"
    :in-rubric="inRubric"
  />
  <ObserveConversationTask
    v-else-if="taskAttempt && 'conversation_id' in taskAttempt.payload"
    :conversation-id="taskAttempt.payload.conversation_id"
  />
</template>
