<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import type { GetCohortStudent } from '@/open-api/generated'
import useGetCohortStudents from '@/composables/api/queries/useGetCohortStudents'
import useRemoveStudentFromCohort from '@/composables/api/mutations/useRemoveStudentFromCohort'
import { DataTable } from '@/components/modern/ui/data-table'
import { useColumns } from '@/components/modern/tables/cohort-students'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Tabs, TabsList, TabsTrigger } from '@/components/modern/ui/tabs'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Learners List',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohort Learners')

const route = useRoute('Modern Cohorts - Cohort Learners List')
const cohortId = computed(() => route.params.cohortId)

const authStore = useAuthStore()

const notificationStore = useNotificationStore()

const { isLoading, students, refetch } = useGetCohortStudents({ cohortId, notificationStore })

const userId = ref<string | null>(null)
const { execute } = useRemoveStudentFromCohort({ userId, cohortId, notificationStore })
const requestRemoveStudent = (student: GetCohortStudent) => (userId.value = student.id)
const confirmRemoveStudent = async () => execute().finally(refetch)

const cohortStudents = useColumns({ requestRemoveStudent, confirmRemoveStudent })
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton
        v-if="authStore.isAtLeastInstitutionAdminUser"
        :to="{ name: 'Modern Cohorts - Cohort Administration' }"
        name="cohort administration"
      />
      <BackButton v-else :to="{ name: 'Modern Cohorts - My Cohorts List' }" name="all cohorts" />
    </template>
    <template #center>
      <Tabs default-value="learners">
        <TabsList class="grid w-full grid-cols-2">
          <TabsTrigger value="activities" as-child>
            <RouterLink
              :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
            >
              Activities
            </RouterLink>
          </TabsTrigger>
          <TabsTrigger value="learners"> Learners </TabsTrigger>
        </TabsList>
      </Tabs>
    </template>
    <template v-if="authStore.isAtLeastInstitutionAdminUser" #right>
      <Button variant="default" size="xs" as-child>
        <RouterLink :to="{ name: 'Modern Cohorts - Add Learners to Cohort', params: { cohortId } }">
          <PlusIcon class="mr-2 size-4" />
          Add Learners
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <DataTable :data="students" :columns="cohortStudents" :loading="isLoading" />
</template>
