<script setup lang="ts">
import NotificationList from '@/components/NotificationList.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'

const props = defineProps<{ loading: boolean }>()
</script>

<template>
  <NotificationList />
  <AppLoadingSpinner v-if="props.loading" :loading="props.loading" center-screen />
  <router-view v-else class="mx-auto" />
</template>
