import { OpenAPI } from './generated'
import { HILBERT_URL } from '@/constants'
import {
  getAccessTokenSilentlyOutsideComponent,
  getIsAuthenticatedOutsideComponent
} from '@/utils/auth0'
import $posthog from '@/utils/posthog'
import {
  AnalyticsService,
  ManagementService,
  ContentService,
  CohortService,
  ConversationService,
  AssignmentService,
  AssignmentV2Service,
  MyAssignmentsService,
  BillingService,
  UserService,
  DataScienceService
} from './generated'

type Headers = {
  'Content-Type': string
  'X-Posthog-Session-ID'?: string
}
const getOpenAPIHeaders = async () => {
  const headers: Headers = {
    'Content-Type': 'application/json'
  }
  // Add posthog session id to make debugging on the server easier:
  if ($posthog) {
    const session_id = $posthog.get_session_id()
    headers['X-Posthog-Session-ID'] = session_id
  }

  return headers
}

// Add authentication token to requests
// Awful hack to make the types line up :(
const getAuthenticationToken = async () => {
  if (getIsAuthenticatedOutsideComponent().value) {
    // Only add token to request if user is authenticated:
    const token = await getAccessTokenSilentlyOutsideComponent()
    return token as string
  } else {
    return ''
  }
}

OpenAPI.BASE = HILBERT_URL
OpenAPI.HEADERS = getOpenAPIHeaders
OpenAPI.TOKEN = getAuthenticationToken

export default {
  Analytics: AnalyticsService,
  Management: ManagementService,
  Content: ContentService,
  Conversation: ConversationService,
  Assignment: AssignmentService,
  MyAssignments: MyAssignmentsService,
  Billing: BillingService,
  User: UserService,
  DataScienceApi: DataScienceService,
  V2: {
    Cohorts: CohortService,
    Assignments: AssignmentV2Service
  }
}
