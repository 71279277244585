<script lang="ts" setup>
import { onMounted } from 'vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'Authorize from Auth0',
  meta: {
    permissionLevel: 'ANY'
  }
})

const router = useRouter()
const { isAuthenticated } = useAuth0()
const authStore = useAuthStore()

async function handleCallback() {
  if (isAuthenticated) {
    if (authStore.flagModernEnabled) {
      router.push({ name: 'Modern - My Activities' })
    } else {
      router.push({ name: 'MyAssignments' })
    }
  } else {
    router.push({ name: 'Login' })
  }
}

onMounted(handleCallback)
</script>

<template>
  <AppLoadingSpinner loading center-screen />
</template>
