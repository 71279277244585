<script setup lang="ts">
import { computed, type Component } from 'vue'
import { Button } from '@/components/modern/ui/button'
import { useLink, type RouteLocationRaw } from 'vue-router'

export type NavItemRoute = {
  label: string
  to: RouteLocationRaw
  icon: Component
  exact?: boolean
}

const props = defineProps<{
  route: NavItemRoute
}>()
const { isActive, isExactActive } = useLink({ to: props.route.to })

const activeStatus = computed(() => {
  if (props.route.exact) {
    return isExactActive.value
  }
  return isActive.value
})
</script>

<template>
  <Button
    variant="ghost"
    size="xs"
    as-child
    class="group w-full items-center justify-start p-1 font-normal hover:bg-border/70"
    :class="{ 'bg-border': activeStatus }"
  >
    <router-link :to="route.to">
      <component
        :is="route.icon"
        class="mr-1.5 size-4 flex-shrink-0 group-hover:stroke-accent-foreground"
      />
      <span class="group-hover:text-accent-foreground">{{ route.label }}</span>
    </router-link>
  </Button>
</template>
