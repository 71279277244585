<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import posthog from 'posthog-js'
import { useAuth0 } from '@auth0/auth0-vue'
import { useUtilsStore } from '@/stores/utils'
import { useAuthStore } from '@/stores/auth'
import { useSimulationStore } from '@/stores/simulation'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { onMounted } from 'vue'
import UAParser from 'ua-parser-js'
import { watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Api from '@/open-api'
import { IS_LOCAL } from '@/constants'
import type { WhoAmIOutput } from './open-api/generated'
import DesktopLayout from '@/layouts/DesktopLayout.vue'
import MobileLayout from '@/layouts/MobileLayout.vue'
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout.vue'

const { isLoading, isAuthenticated, user, error } = useAuth0()
const utilsStore = useUtilsStore()
const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const notificationStore = useNotificationStore()
const simulationStore = useSimulationStore()

const whoAmILoading = ref(false)

// whoAmILoading is used both for initial load (where `isLoading` will be true),
// and for the background re-authentication (where `isLoading` will be false)
// We can't render the authenticated layout until we've finished loading first the
// auth0 and then the whoAmI call, but since whoAmILoading will also become true at points
// during using the app, we can't simply use `isLoading || whoAmILoading`. That will cause the app to re-render during background re-auth.
//
// What we want is a value that:
// * tracks with the state of isLoading, but
// * delays setting itself to false until whoAmI has finished loading, and
// * doesn't set itself to true if whoAmILoading goes true by itself (backgorund re-auth)
const coveredLoading = ref(isLoading.value)
watch(isLoading, (isLoadingValue) => {
  if (isLoadingValue) {
    coveredLoading.value = true
  }
})
watch(whoAmILoading, (whoAmILoadingValue) => {
  if (!whoAmILoadingValue) {
    coveredLoading.value = false
  }
})

const supportedBrowser = () => {
  const supportedBrowsers = [
    'Chrome',
    'Mobile Safari',
    'Safari',
    'Edge',
    'Firefox',
    'Chromium',
    'Mozilla',
    'Android Browser',
    'Oculus Browser'
  ]
  const currentBrowserName = new UAParser(navigator.userAgent).getBrowser().name

  return supportedBrowsers.includes(currentBrowserName || '')
}

onMounted(() => {
  if (!supportedBrowser() && route.name !== 'Unsupported Browser') {
    router.push({ name: 'Unsupported Browser' })
  }
})

// ==================================================
// PostHog & Sentry User Context Setup
// ==================================================

const identifyUser = (data?: WhoAmIOutput) => ({
  // Prefer info in this order:
  //   1. the active/just-returned WhoAmI call
  //   2. the Auth0 user object
  //   3. the information we have in authStore
  // Not all sources have all of the desired information
  // Do this logic just once and use for both Sentry and Posthog
  id: data?.user_id ?? authStore.whoAmI?.user_id,

  user_id: data?.user_id ?? authStore.whoAmI?.user_id,
  auth0_user_id: data?.auth0_user_id ?? user.value?.sub ?? authStore.whoAmI?.auth0_user_id,
  user_role: data?.user_role ?? user.value?.user_role ?? authStore.whoAmI?.user_role,
  organization_id: data?.organization_id ?? user.value?.organization_id,
  auth0_organization_id: data?.auth0_org_id ?? user.value?.org_id ?? authStore.whoAmI?.auth0_org_id,
  organization_name: user.value?.organization_name
})

const setSentryAndPosthog = async (data: WhoAmIOutput) => {
  const { id, ...userInfo } = identifyUser(data)
  Sentry.setUser({
    id,
    ...userInfo
  })
  posthog.identify(id, userInfo)
  posthog.startSessionRecording()
}

async function updateUserContext() {
  try {
    whoAmILoading.value = true
    const res = await Api.User.whoAmIEndpoint()
    authStore.setWhoAmI(res)
    if (!IS_LOCAL) {
      // fire off the sentry and posthog identify requests
      // note: no 'await' here, as we don't want to block on this
      setSentryAndPosthog(res)
    }
  } catch (err: any) {
    // the `Sentry.init` call in src/main.ts sets an `ignoredErrors` property
    // that will discard certain exceptions captured here
    Sentry.withScope((scope) => {
      scope.setUser(identifyUser())
      scope.setTag('source-code', 'src/App.vue > updateUserContext')
      Sentry.captureException(err)
    })
    // Geo Block
    if (err?.body?.message === "Sorry, but you can't log in from your current location.") {
      authStore.setGeoError(true)
    }

    notificationStore.addNotification({
      subtitle: err?.body?.message || 'There has been an error',
      status: NotificationStatus.DANGER
    })
  } finally {
    whoAmILoading.value = false
  }
}

watch(user, (user) => {
  //TODO: this fires AFTER navigation
  // if we can do it before, authStore doesn't need persistence
  if (isAuthenticated.value && user?.sub) {
    // Update user context
    updateUserContext()
  } else {
    // Clear auth store
    authStore.clearStore()
    // Clear simulation store
    simulationStore.clearStore()
    // Clear user-tracking sessions
    if (!IS_LOCAL) {
      Sentry.setUser(null)
      posthog.reset()
    }
  }
})

watch(error, (error) => {
  if (error) {
    notificationStore.addNotification({
      subtitle: error.message || 'There has been an error',
      status: NotificationStatus.DANGER
    })
  }
})
</script>

<template>
  <div
    class="flex h-dvh w-screen flex-col overflow-hidden md:flex-row"
    :style="`--org-color: ${authStore.orgColor}`"
  >
    <UnauthenticatedLayout
      v-if="!isAuthenticated || coveredLoading"
      :loading="isLoading || whoAmILoading"
    />
    <DesktopLayout v-else-if="!utilsStore.isMobile && authStore.isAtLeastEducatorUser" />
    <MobileLayout v-else />
  </div>
</template>
