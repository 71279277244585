<script setup lang="ts">
import { watch, computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useScrollToTop from '@/composables/useScrollToTop'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import useGetAssignment from '@/composables/api/queries/useGetAssignment'
import useTasks from '@/composables/useTasks'
import useCopyActivityToOrganization from '@/composables/api/mutations/useCopyActivityToOrganization'
import useUpdateAssignmentV2 from '@/composables/api/mutations/useUpdateAssignmentV2'
import useAssignToCohort from '@/composables/api/mutations/useAssignToCohort'
import { schema, type Schema } from '@/composables/api/mutations/schema/useUpdateAssignmentV2'
import useUpdateAssignmentTasks, {
  fromSchemaToForm
} from '@/composables/api/mutations/useUpdateAssignmentTasks'
import { CommandState } from '@/composables/api/mutations/types'
import {
  AutoForm,
  type Config,
  type Dependency,
  DependencyType
} from '@/components/modern/ui/auto-form'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import {
  LABEL_ACTIVITY_NAME,
  LABEL_ACTIVITY_DESCRIPTION,
  LABEL_ACTIVITY_INSTRUCTIONS,
  DESCRIPTION_ACTIVITY_NAME,
  DESCRIPTION_ACTIVITY_DESCRIPTION,
  DESCRIPTION_ACTIVITY_INSTRUCTIONS
} from '@/constants/forms'
import { NewTask, AnyTaskForm } from '@/components/modern/task-forms'
import { TopLine, BackButton } from '@/components/modern/page-navigation'
import { Button } from '@/components/modern/ui/button'
import { SearchSelect } from '@/components/modern/ui/search-select'
import AssignToCohortFormDialog from '@/components/modern/AssignToCohortFormDialog.vue'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '@/components/modern/ui/dialog'
import { EyeOpenIcon, CheckboxIcon, PlayIcon, CopyIcon, Cross1Icon } from '@radix-icons/vue'
import { IS_PRODUCTION } from '@/constants'
import useSyncAssignmentToRegions from '@/composables/api/mutations/useSyncAssignmentToRegions'
import { InboxIcon } from '@heroicons/vue/24/outline'

definePage({
  name: 'Modern Activities - Edit Activity',
  meta: {
    permissionLevel: 'Educator',
    isModern: true
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Edit Activity')

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const route = useRoute('Modern Activities - Edit Activity')
// intentional alias
const activityId = computed((): string => route.params.activityId)
const assignmentId = computed<string>(() => route.params.activityId)

// Set up assignment and task editing

const { assignment, refetch } = useGetAssignment({ assignmentId, notificationStore })
const { tasks, addTask, ...handlers } = useTasks(assignment)
const hasTasks = computed<boolean>(() => tasks.value.length > 0)
const taskErrors = ref<string[][]>([])
const hasTaskErrors = computed<boolean>(() => taskErrors.value.flat().length > 0)

const updateAssignment = useUpdateAssignmentV2({ assignmentId, notificationStore })
const updateTasks = useUpdateAssignmentTasks({ assignmentId, notificationStore })
const update = async (values: Schema) => {
  await Promise.all([
    updateAssignment.execute(values),
    updateTasks.execute({ tasks: tasks.value.map(fromSchemaToForm) })
  ])

  if (
    updateAssignment.state.value === CommandState.SUCCESS ||
    updateTasks.state.value === CommandState.SUCCESS
  ) {
    refetch()
  }

  updateAssignment.reset()
  updateTasks.reset()
}
const updateInProgress = computed(
  () =>
    updateAssignment.state.value === CommandState.IN_PROGRESS ||
    updateTasks.state.value === CommandState.IN_PROGRESS
)

// Form setup

const form = useForm({ validationSchema: toTypedSchema(schema) })
const submit = form.handleSubmit(async (values: Schema) => {
  if (hasTaskErrors.value) {
    notificationStore.addDANGER(taskErrors.value.flat()[0])
    return
  }

  await update(values)
})

const fieldConfig: Config<Schema> = {
  name: {
    label: LABEL_ACTIVITY_NAME,
    description: DESCRIPTION_ACTIVITY_NAME
  },
  description: {
    label: LABEL_ACTIVITY_DESCRIPTION,
    component: 'textarea',
    description: DESCRIPTION_ACTIVITY_DESCRIPTION
  },
  instructions: {
    label: LABEL_ACTIVITY_INSTRUCTIONS,
    hideRequired: true,
    component: 'richtext',
    description: DESCRIPTION_ACTIVITY_INSTRUCTIONS
  }
}

const dependencies = computed((): Dependency<Schema>[] => [
  {
    sourceField: 'name',
    type: DependencyType.DISABLES,
    targetField: 'name',
    when: () => !authStore.flagAuthoringEnabled
  },
  {
    sourceField: 'description',
    type: DependencyType.DISABLES,
    targetField: 'description',
    when: () => !authStore.flagAuthoringEnabled
  },
  {
    sourceField: 'instructions',
    type: DependencyType.DISABLES,
    targetField: 'instructions',
    when: () => !authStore.flagAuthoringEnabled
  }
])

// Fill in the form with the server values from GetAssignment
watch(assignment, () => {
  form.resetForm({
    values: {
      name: assignment.value?.name,
      description: assignment.value?.description,
      instructions: assignment.value?.instructions
    }
  })
})

// Set up copy activity
const selectedOrganizationId = ref<string | undefined>(undefined)
const { organizations, organizationsLoading } = useAllOrganizations()
const copyModalOpen = ref<boolean>(false)
const copyActivity = useCopyActivityToOrganization({
  assignmentId,
  organizationId: selectedOrganizationId,
  notificationStore
})
const router = useRouter()
const scrollToTop = useScrollToTop()

const copyActivityToOrganization = async () => {
  const { execute, reset } = copyActivity
  const newActivityId = await execute().finally(reset)

  if (newActivityId) {
    copyModalOpen.value = false
    const selectedOrganization = organizations.value.find(
      (org) => org.organization_id === selectedOrganizationId.value
    )
    if (selectedOrganization) {
      notificationStore.addWARNING(`Now viewing copy belonging to ${selectedOrganization.name}.`)
    }
    router.push({
      name: 'Modern Activities - Edit Activity',
      params: { activityId: newActivityId }
    })
    scrollToTop()
  }
}

// Global Content.
const syncAssignmentToRegions = useSyncAssignmentToRegions({
  assignmentId,
  notificationStore,
  authStore
})

const assignToCohort = useAssignToCohort({ notificationStore })
</script>

<template>
  <TopLine>
    <template #left>
      <BackButton :to="{ name: 'Modern Activities - All Activities List' }" name="all activities" />
    </template>
    <template #right>
      <template v-if="authStore.flagAuthoringEnabled">
        <Button
          v-if="authStore.isSuperAdminUser && IS_PRODUCTION"
          :disabled="syncAssignmentToRegions.state.value === CommandState.IN_PROGRESS"
          variant="outline"
          size="xs"
          @click="syncAssignmentToRegions.execute"
        >
          <CopyIcon class="mr-2 size-4" />
          <span>Sync to Regions</span>
        </Button>

        <Dialog v-if="authStore.isAtLeastStaffUser" v-model:open="copyModalOpen">
          <DialogTrigger as-child>
            <Button variant="outline" size="xs">
              <CopyIcon class="mr-2 size-4" />
              <span>Copy to Organization</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Copy activity to organization</DialogTitle>
              <DialogDescription>
                Select an organization to copy this activity to.
              </DialogDescription>
            </DialogHeader>
            <div class="min-w-full">
              <SearchSelect
                v-model:selected-value="selectedOrganizationId"
                :data="organizations"
                :loading="organizationsLoading"
                value-key="organization_id"
                label-key="name"
                placeholder-label="organization"
              />
            </div>
            <DialogFooter>
              <DialogClose as-child>
                <Button variant="outline" size="xs">
                  <Cross1Icon class="mr-2 size-4" />
                  <span>Cancel</span>
                </Button>
              </DialogClose>
              <Button
                variant="default"
                size="xs"
                :disabled="copyActivity.state.value === CommandState.IN_PROGRESS"
                @click="copyActivityToOrganization"
              >
                <CopyIcon class="mr-2 size-4" />
                <span>Copy</span>
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Button v-if="authStore.isAtLeastInstitutionAdminUser" variant="outline" size="xs" as-child>
          <RouterLink
            :to="{
              name: 'Modern Activities - Activity Access List',
              params: { activityId: assignmentId }
            }"
          >
            <EyeOpenIcon class="mr-2 size-4" />
            <span>Access Control</span>
          </RouterLink>
        </Button>
      </template>

      <AssignToCohortFormDialog :activity-id="activityId" :assign-to-cohort="assignToCohort">
        <Button variant="outline" size="xs">
          <InboxIcon class="mr-2 size-4" />
          <span>Assign</span>
        </Button>
      </AssignToCohortFormDialog>

      <Button variant="default" size="xs" as-child>
        <RouterLink
          :to="{
            name: 'Modern Activities - Preview Activity',
            params: { activityId: assignmentId }
          }"
        >
          <PlayIcon class="mr-2 size-4" />
          <span>Preview Activity</span>
        </RouterLink>
      </Button>
    </template>
  </TopLine>
  <div class="flex w-full flex-row justify-center p-4 py-2 lg:px-6 lg:py-4">
    <div class="w-full max-w-4xl space-y-6">
      <AutoForm
        :schema="schema"
        :form="form"
        :field-config="fieldConfig"
        :dependencies="dependencies"
      >
        <Button
          v-if="authStore.flagAuthoringEnabled"
          variant="default"
          size="xs"
          :disabled="updateInProgress"
          @click="submit"
        >
          <CheckboxIcon class="mr-2 size-4" />
          <span>Save Changes</span>
        </Button>
      </AutoForm>
      <div class="w-full flex-col space-y-6">
        <template v-if="hasTasks">
          <AnyTaskForm
            v-for="(task, index) in tasks"
            :key="task.assignmentTaskId"
            :task="task"
            :index="index"
            :is-last="index === tasks.length - 1"
            :is-disabled="!authStore.flagAuthoringEnabled"
            v-on="handlers"
            @task-errors="(index, errors) => (taskErrors[index] = errors)"
          />
          <div v-if="authStore.flagAuthoringEnabled" class="ml-4 space-y-6 pl-9 lg:ml-6">
            <Button variant="default" size="xs" :disabled="updateInProgress" @click="submit">
              <CheckboxIcon class="mr-2 size-4" />
              <span>Save Changes</span>
            </Button>
            <NewTask @add-task="addTask" />
          </div>
        </template>
        <template v-else>
          <p class="w-full text-center text-muted-foreground">
            No tasks have been added yet. Add a task from the options below.
          </p>
          <NewTask @add-task="addTask" />
        </template>
      </div>
    </div>
  </div>
</template>
