import { Environments } from '@/types/utils'

export const IS_PRODUCTION: boolean = (() => import.meta.env.VITE_ENV === Environments.PROD)()

export const IS_LOCAL: boolean = (() => import.meta.env.VITE_ENV === Environments.LOCAL)()

export const POSTHOG_KEY: string = (() => {
  return import.meta.env.VITE_POSTHOG_KEY ?? ''
})().toString()

export const POSTHOG_HOST: string = (() => {
  return import.meta.env.VITE_POSTHOG_HOST ?? ''
})().toString()

export const HILBERT_URL: string = (() => {
  return import.meta.env.VITE_SC_HILBERT_URL ?? ''
})().toString()

export const AUTH0_DOMAIN: string = (() => {
  return import.meta.env.VITE_AUTH0_DOMAIN ?? ''
})().toString()

export const AUTH0_CLIENTID: string = (() => {
  return import.meta.env.VITE_AUTH0_CLIENTID ?? ''
})().toString()

export const AUTH0_AUDIENCE: string = (() => {
  return import.meta.env.VITE_AUTH0_AUDIENCE ?? ''
})().toString()
